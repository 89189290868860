import { useEffect, useState } from 'react'
import useDataHooks from '@/hooks/useDataHooks'
import axios from 'axios'

type Types = {
  account: string | undefined
  isRefresh: boolean
}

export function useHomeHooks({ account, isRefresh }: Types) {
  const { constant } = useDataHooks().data

  const [overDetails, setOverDetails] = useState<any | undefined>(undefined)
  const [overInfoTrue, setOverInfoTrue] = useState<boolean>(false)

  useEffect(() => {
    if (account) getOverDetails()
    else {
      setOverDetails(undefined)
      setOverInfoTrue(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isRefresh])

  const getOverDetails = async () => {
    try {
      let overNft: any = await constant.ContractStarshipNFT.methods.walletOfOwner(account).call()
      if (overNft.length > 0) {
        let tokenId = overNft[0]
        let tokeData = await getAxiosNftDetails({ tokenId })
        console.log('tokeData', tokeData)
        setOverDetails({
          ...tokeData,
          tokenId,
        })
        setOverInfoTrue(true)
      } else setOverInfoTrue(false)
    } catch (error) {
      setOverInfoTrue(false)
    }
  }

  return { overDetails, overInfoTrue }
}

const getAxiosNftDetails = async ({ tokenId }: { tokenId: string }): Promise<object | undefined> => {
  try {
    const { data } = await axios.get(
      `https://bafybeib36d5m4mjzmj2d2szg6wnf25gihgscrer2d3qlft3a56gbabmd6m.ipfs.nftstorage.link/${tokenId}.json`,
    )
    return await data
  } catch (error) {
    return await undefined
  }
}

export async function getAxiosWhiteList({ cid }: { cid: string }): Promise<string[]> {
  try {
    const { data } = await axios.get(`https://${cid}.ipfs.4everland.io/`)
    return (await data.whiteLists) ? data.whiteLists : []
  } catch (error) {
    return []
  }
}
