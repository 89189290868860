import { useEffect, useMemo, useState } from 'react'
import { message, Image } from 'antd'
import { useBoolean } from 'ahooks'
import {
  HomeSubtitle,
  HomeTitle,
  HomeWrapper,
  HomeLoading,
  HomeContent,
  HomeMintBtn,
  MintTip,
  HomeOverDetails,
  HomeRightContent,
  HomeDescriptionTitle,
  HomeContentVerifyTrue,
} from './styled'
import { useWeb3React } from '@web3-react/core'
import { metaMask } from '@/connectors/metaMask'
import { DEFAULT_CHAINID } from '@/contracts/constant'
import { getAddChainParameters } from '@/contracts/chains'
import { useTranslation } from 'react-i18next'
import { verify, getProofForAddress } from '@/common/markle'
import ConnectWallet from '@/components/ConnectWallet'
import useDataHooks from '@/hooks/useDataHooks'
import { useHomeHooks, getAxiosWhiteList } from '@/hooks/useHomeHooks'
import { fallbackImage } from '@/common'

const Home = () => {
  const { web3, constant } = useDataHooks().data
  const { isActive, isActivating, account } = useWeb3React()
  // @ts-ignore
  const { ethereum } = window
  const { t } = useTranslation()

  const [isRefresh, setIsRefresh] = useBoolean(false)

  const { overDetails, overInfoTrue } = useHomeHooks({ account, isRefresh })
  const [mintLoading, setMintLoading] = useState<boolean>(false)
  const [verifyTrue, setVerifyTrue] = useState<boolean>(true)
  const [verifyTrueLoading, setVerifyTrueLoading] = useState<boolean>(false)

  useMemo(() => {
    if (!isActive && ethereum && ethereum.isMetaMask) void metaMask.activate(getAddChainParameters(DEFAULT_CHAINID))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isActive) handleMintVerify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, account])

  const handleMintVerify = async () => {
    setVerifyTrueLoading(true)
    if (!account) {
      setVerifyTrueLoading(false)
      return false
    }
    try {
      const cid = await constant.ContractStarshipNFT.methods.whitelistPrefix().call()
      const whiteList = await getAxiosWhiteList({ cid: cid.slice(7, cid.length - 1) })
      let root = await constant.ContractStarshipNFT.methods.merkleRoot().call()
      let isVerifyTrue = await verify({ arr: [account], web3, root, whiteList })
      console.log('isVerifyTrue', isVerifyTrue)
      setVerifyTrue(isVerifyTrue)
      setVerifyTrueLoading(false)
      if (!isVerifyTrue) {
        message.info({
          content: t('home.nowhitelist.tips'),
          className: 'message-global',
        })
      }
    } catch (error) {
      setVerifyTrueLoading(false)
      console.log(error)
    }
  }

  const handleMintClick = async () => {
    try {
      if (!account) return
      setMintLoading(true)
      const cid = await constant.ContractStarshipNFT.methods.whitelistPrefix().call()
      const whiteList = await getAxiosWhiteList({ cid: cid.slice(7, cid.length - 1) })
      let markeProof = getProofForAddress({ addr: account as any, web3, whiteList })
      constant.ContractStarshipNFT.methods
        .whitelistMint('1', markeProof)
        .send({
          from: account,
        })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          setMintLoading(false)
          void setIsRefresh.toggle()
          message.success({
            content: t('home.success.tips'),
            className: 'message-global',
          })
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          setMintLoading(false)
        })
    } catch (error) {
      setMintLoading(false)
      console.log(error)
    }
  }

  return (
    <HomeWrapper>
      {isActivating && <HomeLoading size="large" tip={t('app.loading')} spinning={true} />}
      {isActive && verifyTrueLoading && <HomeLoading size="large" tip={t('app.loading')} spinning={true} />}
      {!overInfoTrue && (
        <>
          {!isActive && !isActivating && (
            <HomeContentVerifyTrue>
              {!verifyTrueLoading && (
                <>
                  <HomeTitle>{t('home.title')}</HomeTitle>
                  <HomeSubtitle>
                    <p>{t('home.sub.title.1')}</p>
                    <p>{t('home.sub.title.2')}</p>
                  </HomeSubtitle>
                </>
              )}
              <ConnectWallet isPosition="Home" />
            </HomeContentVerifyTrue>
          )}
          {isActive && !verifyTrueLoading && (
            <>
              {verifyTrue && (
                <HomeContentVerifyTrue>
                  <HomeTitle>{t('home.title')}</HomeTitle>
                  <HomeSubtitle>
                    <p>{t('home.sub.title.1')}</p>
                    <p>{t('home.sub.title.2')}</p>
                  </HomeSubtitle>
                  <HomeMintBtn onClick={handleMintClick} loading={mintLoading}>
                    {t('home.mint.btn')}
                  </HomeMintBtn>
                </HomeContentVerifyTrue>
              )}
              {!verifyTrue && (
                <HomeContent>
                  <MintTip>{t('home.nowhitelist.tips')}</MintTip>
                </HomeContent>
              )}
            </>
          )}
        </>
      )}
      {overInfoTrue && overDetails && !isActivating && !verifyTrueLoading && (
        <HomeOverDetails>
          <div className="details-top">
            <Image
              fallback={fallbackImage}
              preview={false}
              placeholder={true}
              width="100%"
              src={`https://ipfs.io/ipfs/bafybeibnkpoy5djtvi74ti4jqfggumioc6zzbe4tlbmv6ljrutr6embtn4/${overDetails.tokenId}.png`}
            ></Image>
            <HomeRightContent>
              <HomeDescriptionTitle>{overDetails.name}</HomeDescriptionTitle>
              {overDetails.attributes.map((item: any, i: number) => (
                <p key={i}>
                  <span>{item.trait_type}:</span> {item.value}
                </p>
              ))}
            </HomeRightContent>
          </div>
          <div className="details-content">
            <HomeDescriptionTitle>{t('home.nft.title')}</HomeDescriptionTitle>
            <p>{overDetails.description}</p>
          </div>
        </HomeOverDetails>
      )}
    </HomeWrapper>
  )
}

export default Home
