const home = {
  'home.mint.btn': 'Mint',
  'home.nowhitelist.tips': 'User address is not whitelisted',
  'home.success.tips': 'Successfully cast',
  'home.nft.title': 'NFT Description',
  'home.title': 'GridMind',
  'home.sub.title.1': '享受空投權益',
  'home.sub.title.2': 'GridMind Ai鏈，回饋早期對人工智能發展給予關注和支持的有識之士',
}

export default home
